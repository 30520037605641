/* Styles go here */
.navbar a {
    color: #777777 !important;
}
#menu div .navbar-nav .dropdown, #menu div .navbar-nav .navbar-nav {
    margin: auto;
}

@media (max-width: 900px) {
    .menuLinks {
        text-align: right !important;
    }

    .menuLinks button.navbar-toggler {
        position: absolute;
        margin-top: -45px;
        margin-left: -45px;
    }

    #loggoutContainer {
        text-align: center;
    }

    #filterLinkIconMobile {
        float: left;
        width: 100%;
        text-align: right;
    }

    #filterLinkIconMobile svg {
        width: 25px;
    }
}

@media (min-width: 900px) {
    #basic-navbar-nav {
        float: right;
        width: 100%;
        display: inline-block !important;
    }

    .menuLinks {
        text-align: left !important;
    }
}

#accountAddr {
    line-height: 38px;
}

#modalNftSingle {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.3);
    z-index: 9999;
}

#modalNftSingleData {
    position: relative;
    width: 80%;
    /*height: 300px;*/
    height: auto;
    background: white;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin: 0 auto;
}

a.attribute {
    float: left;
    width: 100%;
    height: 40px;
    line-height: 40px;
}
